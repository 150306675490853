<app-report-subscription-banner *ngIf="allowBanner"></app-report-subscription-banner>
<p-toast position="top-right"></p-toast>
<div *ngIf="!queryRunning && traceResponseArray && traceResponseArray.length===0">
    <mat-card>
        <mat-icon fxFlex="99" fxFlex.gt-sm="3" style="color: red;">warning</mat-icon>
        <div fxFlex="99" fxFlex.gt-sm="50" style="line-height: 1.7;">
            <span class="smallFont"
                [innerHTML]="'clientDoesNotHaveAnyVehicleOrDriversToShowTachoLive'|translate"></span>
        </div>
    </mat-card>
</div>
<div [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
    <mat-form-field *ngIf="dataexists && traceResponseArray" style="padding: 0 0.666rem; margin-top: 1rem;">
        <input matInput type="text" id="myInput" [value]="filterValue" [(ngModel)]="filterValue"
            (keyup.enter)="searchFunction()" placeholder="{{'Search by driver' |translate}}">
    </mat-form-field>
    <mat-icon *ngIf="dataexists && traceResponseArray" matTooltip="{{'Export data to pdf or for printing' | translate}}"
        matTooltipPosition="before" (click)="myPrint()" style="float: right; cursor: pointer; font-size: 45px;
                margin-right: 2rem; margin-top: 1rem;" color="primary">print
    </mat-icon>
    <mat-icon *ngIf="dataexists && traceResponseArray" (click)="refreshPage()"
        matTooltip="{{'Refresh page' | translate}}" matTooltipPosition="before" color="primary"
        style="float: right; cursor: pointer; font-size: 45px;margin-right: 2rem; margin-top: 1rem;">
        refresh
    </mat-icon>
    <mat-card *ngIf="!traceResponseArray" fxLayout="row" fxLayoutWrap="wrap">
        <mat-icon fxFlex="99" fxFlex.gt-sm="3" style="color: #27bd29;">warning</mat-icon>
        <div fxFlex="99" fxFlex.gt-sm="50" style="line-height: 1.7;"><span>{{'No activity records found' |
                translate}}!</span>
        </div>
    </mat-card>
</div>

<div style="padding: 1rem" [@animate]="{value:'*',params:{scale:'.2',delay:'200ms'}}">
    <table mat-table *ngIf="dataexists && traceResponseArray"
        style="width: 100%; padding: 0px; overflow-y: scroll; background: transparent; border: #333333; border-radius: 1px"
        [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumn">
            <th style="background: #fafafa" mat-header-cell *matHeaderCellDef>
                <mat-icon *ngIf="column === 'state'" (click)="sortByStatus()" style=" height: 28px; width: 28px; "
                    matTooltip="{{'Status' | translate}}" svgIcon="status"></mat-icon>
                <mat-icon *ngIf="column === 'driver'" (click)="sortByDriverName()"
                    matTooltip="{{'Drivers' | translate}}">
                    person
                </mat-icon>
                <mat-icon *ngIf="column === 'drive'" svgIcon="kormany"
                    matTooltip="{{'Continuous driving' | translate}}"></mat-icon>
                <mat-icon style="width: 55px; height: 23px;" svgIcon="24h" matTooltip="{{'Daily driving' | translate}}"
                    *ngIf="column === 'drive24'"></mat-icon>
                <mat-icon style="width: 30px; height: 30px;" svgIcon="kormany_1"
                    matTooltip="{{'Weekly driving' | translate}}" *ngIf="column === 'drivedriver'"></mat-icon>
                <mat-icon style="width: 30px; height: 30px;" svgIcon="kormany_2"
                    matTooltip="{{'Two weekly driving' | translate}}" *ngIf="column === 'drivecodriver'"></mat-icon>
                <mat-icon *ngIf="column === 'rest'" svgIcon="bed" matTooltip="{{'Rest' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'nextrest'" svgIcon="bed"
                    matTooltip="{{'Next rest' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'nextrest'" matTooltip="{{'Next rest' | translate}}">play_arrow</mat-icon>
                <mat-icon svgIcon="next_work" *ngIf="column === 'Next work'"
                    matTooltip="{{'Next work' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'work'" svgIcon="work" matTooltip="{{'Work' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'dailyWork'" style="width: 20px; margin-right: 5px" svgIcon="kormany"
                    matTooltip="{{'Daily work' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'dailyWork'" style="width: 43px" svgIcon="daily_work"
                    matTooltip="{{'Daily work' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'weeklyWork'" style="width: 20px; margin-right: 5px" svgIcon="kormany"
                    matTooltip="{{'Weekly work' | translate}}"></mat-icon>
                <mat-icon *ngIf="column === 'weeklyWork'" svgIcon="weekly_work"
                    matTooltip="{{'Weekly work' | translate}}"></mat-icon>
            </th>
            <td mat-cell *matCellDef="let item"
                [ngClass]="getHoursFromADate(item.calculatedValues.baseTime, item.driverInfo.name) < 4 ? 'withoutOpacity' : 'opacity'"
                (click)="getDiagramDataForAPeriod(item)">
                <div *ngIf="column === 'state'">
                    <div>
                        <div style="display: inline-block;" *ngIf="item.lastActivity.activity == 'AVAILABILITY'"
                            matTooltip="{{'AVAILABILITY' | translate}}">
                            <mat-icon svgIcon="available_colored"></mat-icon>
                        </div>
                        <div style="display: inline-block;" *ngIf="item.lastActivity.activity == 'DRIVING'"
                            matTooltip="{{'Driving' | translate}}">
                            <mat-icon svgIcon="kormany_colored"></mat-icon>
                        </div>
                        <div style="display: inline-block;" *ngIf="item.lastActivity.activity == 'WORK'"
                            matTooltip="{{'WORK' | translate}}">
                            <mat-icon svgIcon="work_colored"></mat-icon>
                        </div>
                        <div style="display: inline-block;" *ngIf="item.lastActivity.activity == 'BREAK/REST'"
                            matTooltip="{{'BREAK/REST' | translate}}">
                            <mat-icon svgIcon="bed_colored"></mat-icon>
                        </div>
                        <!--                        <div matTooltip="{{item.calculatedValues.projections.nextWorkComments}}" *ngIf="((item.lastActivity.activity == 'BREAK/REST' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'zold') || (item.lastActivity.activity == 'DRIVING' && item.calculatedValues.drivingContinuousRemaining > 1) || (item.lastActivity.activity == 'AVAILABILITY' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'zold' && item.calculatedValues.drivingContinuousRemaining > 0) || (item.lastActivity.activity == 'WORK' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'zold')) && item.calculatedValues.drivingContinuousRemaining > 0" style="display: inline-block;">-->
                        <!--                            <span style="margin-left: 0.5rem;" class="dot2"></span>-->
                        <!--                        </div>-->
                        <!--                        <div style="display: inline-block;" *ngIf="item.calculatedValues.drivingContinuousRemaining <= 0 || (item.lastActivity.activity == 'BREAK/REST' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'piros') || (item.lastActivity.activity == 'AVAILABILITY' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'piros') ||  (item.lastActivity.activity == 'WORK' && szemafor(item.calculatedValues.projections.nextWorkStart) == 'piros')">-->
                        <!--                            <span style="margin-left: 0.5rem;" class="dot"></span>-->
                        <!--                        </div>-->
                    </div>
                    <div style="font-size: small">
                        {{formatFromNow(item.uploadInformation.uploadTimeStamp)}}
                    </div>
                </div>
                <div *ngIf="column === 'driver'" style="vertical-align: middle; font-size: 12px;">
                    <div class="flex flex-row gap-2 items-center">
                        <div>{{item.driverInfo.name}}</div>
                        <div *ngIf="clientIsPro" >
                            <div *ngIf="!item.remainingSeconds">
                                <mat-icon class="cursor-pointer"
                                    [ngClass]="{'opacity-50': !item.driverInfo.cardInserted}"
                                    (click)="onClickRefreshDataWithoutRDUAdmin(item, $event)"
                                    matTooltip="{{ item.driverInfo.cardInserted? 'Refresh driving times / live data.':'The card is not present in any active vehicle.' | translate}}">sync</mat-icon>
                            </div>
                            <div *ngIf="!!item.remainingSeconds" class="flex flex-col">
                                <div>
                                    {{'New data in' | translate}}
                                </div>
                                <div>
                                    {{formatTime(item.remainingSeconds)}}
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <div>{{item.driverInfo.cardNumber}}</div>
                    <div *ngIf="item.lastActivityDay.crew == true" matTooltip="{{'Crew' | translate}}">
                        <mat-icon style="font-size: 18px;">person</mat-icon>
                        <mat-icon style="margin-left: 3px; font-size: 18px;">person</mat-icon>
                        <!-- <span class="dotCrew"></span>
                        <span class="dotCrew" style="margin-left: 3px" ></span> -->
                    </div>
                    <div *ngIf="item.lastActivityDay.crew != true" matTooltip="{{'NotCrew' | translate}}">
                        <mat-icon style="font-size: 18px;">person</mat-icon>
                        <!-- <span class="dotCrew"></span> -->
                    </div>
                </div>
                <div *ngIf="column === 'drive'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none">{{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining > 30">
                            <div style="text-align: center;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining < 30"
                            style="color: #ee2921 !important;">
                            <div style="text-align: center;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax)}}
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <progress
                                value="{{item.calculatedValues.drivingContinuousMax - item.calculatedValues.drivingContinuousRemaining}}"
                                max="{{item.calculatedValues.drivingContinuousMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining > 30">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax -
                                item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining < 30"
                            style="color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax -
                                item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="column === 'drive24'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingDailyRemaining)}}</div>
                        <div style="text-align: center;"
                            matTooltip="{{('moreThan9hDrivingTimesFromLastWeeklyBreak_'+(item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreakMax - item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreak)) |translate}}">
                            {{this.formatTime(checkDailyDriving(item.calculatedValues))}}
                            <span class="dotCrew" style="margin-left: 3px; float: right !important; margin-top: 6px"
                                *ngFor='let in of counter(item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreakMax - item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreak) ;let i = index'>
                            </span>
                            <span class="dotWithoutBackgroung" style="margin-left: 3px; margin-top: 6px; float: right"
                                *ngFor='let in of counter(item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreak > item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreakMax ?
                                    item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreakMax :
                                    item.calculatedValues.moreThan9hDrivingTimesFromLastWeeklyBreak) ;let i = index'>
                            </span>
                        </div>
                        <div style="text-align: center;">
                            <progress
                                value="{{checkDailyDriving(item.calculatedValues) - item.calculatedValues.drivingDailyRemaining}}"
                                max="{{checkDailyDriving(item.calculatedValues)}}">
                            </progress>
                        </div>
                        <div>
                            <div style="float: left; display: inline-block;">
                                {{formatTime(checkDailyDriving(item.calculatedValues) -
                                item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="column === 'drivedriver'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}</div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining < 500"
                            style="font-size: 12px; text-align: center; color: #ee2921 !important;">
                            {{formatTime(item.calculatedValues.drivingWeeklyMax)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining > 500"
                            style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.drivingWeeklyMax)}}
                        </div>
                        <div style="text-align: center;">
                            <progress value="{{item.drivingWeek}}"
                                max="{{item.calculatedValues.drivingWeeklyMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining < 500"
                            style="font-size: 12px; color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingWeek)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining > 500" style="font-size: 12px;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingWeek)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="column === 'drivecodriver'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining < 1000"
                            style="font-size: 12px; text-align: center; color: #ee2921 !important;">
                            {{formatTime(item.calculatedValues.drivingTwoWeeklyMax)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining > 1000"
                            style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.drivingTwoWeeklyMax)}}
                        </div>
                        <div style="text-align: center;">
                            <progress value="{{item.drivingLast2Weeks}}"
                                max="{{item.calculatedValues.drivingTwoWeeklyMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining < 1000"
                            style="font-size: 12px; color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingLast2Weeks)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining > 1000" style="font-size: 12px;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingLast2Weeks)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="column === 'rest'" style="vertical-align: middle;">
                    <div *ngIf="item.restLastDaily">
                        <mat-icon style="width: 35px; height: 15px;" svgIcon="daily_rest"></mat-icon>
                        <span style="font-size: 12px;"
                            *ngIf="!getDailyHours((item.restLastDaily.restEnd - item.restLastDaily.restStart), 9)">
                            {{formatDateWithoutDay(item.restLastDaily.restStart)}} -
                            {{formatDateWithoutDay(item.restLastDaily.restEnd)}}
                            ({{formatDuration(item.restLastDaily.restEnd - item.restLastDaily.restStart)}})
                        </span>
                        <span style="font-size: 12px; color: red"
                            *ngIf="getDailyHours((item.restLastDaily.restEnd - item.restLastDaily.restStart), 9)">
                            {{formatDateWithoutDay(item.restLastDaily.restStart)}} -
                            {{formatDateWithoutDay(item.restLastDaily.restEnd)}}
                            ({{formatDuration(item.restLastDaily.restEnd - item.restLastDaily.restStart)}})</span>
                    </div>

                    <div *ngIf="item.restLastWeekly1">
                        <mat-icon style="width: 25px; height: 16px; margin-top: 0.5rem;"
                            svgIcon="weekly_rest"></mat-icon>
                        <span style="font-size: 12px; color: orange"
                            *ngIf="getDailyHours((item.restLastWeekly1.end - item.restLastWeekly1.start), 24, 45)">
                            {{formatDateWithoutDay(item.restLastWeekly1.start)}} -
                            {{formatDateWithoutDay(item.restLastWeekly1.end)}}
                            ({{formatDuration(item.restLastWeekly1.end - item.restLastWeekly1.start)}})
                        </span>

                        <span style="font-size: 12px;"
                            *ngIf="!getDailyHours((item.restLastWeekly1.end - item.restLastWeekly1.start), 45)">
                            {{formatDateWithoutDay(item.restLastWeekly1.start)}} -
                            {{formatDateWithoutDay(item.restLastWeekly1.end)}}
                            ({{formatDuration(item.restLastWeekly1.end - item.restLastWeekly1.start)}})
                        </span>

                        <span style="font-size: 12px; color: red"
                            *ngIf="getDailyHours((item.restLastWeekly1.end - item.restLastWeekly1.start), 24)">
                            {{formatDateWithoutDay(item.restLastWeekly1.start)}} -
                            {{formatDateWithoutDay(item.restLastWeekly1.end)}}
                            ({{formatDuration(item.restLastWeekly1.end - item.restLastWeekly1.start)}})
                        </span>
                    </div>
                </div>
                <div *ngIf="column === 'nextrest'" style="vertical-align: middle;">
                    <div *ngIf="item.calculatedValues.nextDailyBreakStart"
                        matTooltip="{{('lessThan11hBreakTimesFromLastWeeklyBreak_'+(item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreakMax - item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak))|translate}}">
                        <mat-icon style="width: 35px; height: 15px;" svgIcon="daily_rest"></mat-icon>
                        <span style="font-size: 12px;">
                            {{formatDateWithoutDay(item.calculatedValues.nextDailyBreakStart)}}
                        </span>
                        <span style="font-size: 12px" *ngIf="item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak >= item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreakMax
                               && item.lastActivityDay.crew == false">
                            (11:00)
                        </span>
                        <span style="font-size: 12px" *ngIf="item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak < 3
                              && item.lastActivityDay.crew == false">
                            (9:00)
                        </span>
                        <span style="font-size: 12px" *ngIf="item.lastActivityDay.crew == true">
                            (9:00)
                        </span>
                        <span class="dotCrew" style="margin-left: 3px; float: right; margin-top: 7px"
                            *ngFor='let in of counter(item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreakMax - item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak) ;let i = index'>
                        </span>
                        <span class="dotWithoutBackgroung" style="margin-left: 3px; float: right; margin-top: 7px"
                            *ngFor='let in of counter(item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak > item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreakMax ?
                                item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreakMax:
                                item.calculatedValues.lessThan11hBreakTimesFromLastWeeklyBreak) ;let i = index'>
                        </span>
                    </div>

                    <div *ngIf="item.calculatedValues.nextWeeklyBreakStart">
                        <mat-icon style="width: 25px; height: 16px; margin-top: 0.5rem;"
                            svgIcon="weekly_rest"></mat-icon>
                        <span style="font-size: 12px">
                            {{formatDateWithoutDay(item.calculatedValues.nextWeeklyBreakStart)}}
                        </span>
                        <span style="font-size: 12px;"
                            *ngIf="getHoursFromDate(item.restLastDaily.restEnd - item.restLastDaily.restStart) > 45">
                            (24:00)
                        </span>
                        <span style="font-size: 12px;"
                            *ngIf="getHoursFromDate(item.restLastDaily.restEnd - item.restLastDaily.restStart) < 45">
                            (45:00)
                        </span>
                    </div>
                </div>
                <div *ngIf="column === 'nextwork'" style="vertical-align: middle; font-size: 12px; min-width: 10%;">
                    <div>
                        <mat-icon svgIcon="next_work"></mat-icon>
                        <span style="vertical-align: top; line-height: 28px;">
                            <span *ngIf="this.checkIfSameDate(item.calculatedValues.projections.nextWorkStart)">
                                {{"now" | translate}}
                            </span>

                            <span *ngIf="!this.checkIfSameDate(item.calculatedValues.projections.nextWorkStart)">
                                {{formatDateWithoutYear(item.calculatedValues.projections.nextWorkStart)}}
                            </span>
                        </span>
                    </div>
                    <div>
                        <mat-icon svgIcon="hossza"></mat-icon>
                        <span style="vertical-align: top; line-height: 23px;">
                            {{formatTime(item.calculatedValues.projections.nextWorkLength)}}</span>
                    </div>
                </div>
                <!-- <div *ngIf="column === 'action' && clientIsPro" >
                    <div *ngIf="!item.remainingSeconds">
                        <mat-icon class="cursor-pointer"
                            (click)="onClickRefreshDataWithoutRDUAdmin(item)"
                            matTooltip="{{'Clink here view live data if available' | translate}}">refresh</mat-icon>
                    </div>
                    <div *ngIf="!!item.remainingSeconds" class="flex flex-col">
                        <div>
                            {{'New data in' | translate}}
                        </div>
                        <div>
                            {{formatTime(item.remainingSeconds)}}
                        </div>
                    </div>

                </div> -->
                <div *ngIf="column === 'work'" style="vertical-align: middle; font-size: 12px;">

                    <div style="float: left;">
                        <div style="font-size: 12px; text-align: center;">
                            {{formatTime(360)}}
                        </div>

                        <div style="text-align: center;">
                            <progress value="{{item.calculatedValues.workTimes.justWorkForToday}}"
                                max="{{360}}"></progress>
                        </div>

                        <div
                            [ngClass]="item.calculatedValues.workTimes.justWorkForToday < 360 ? 'simpleDiv' : 'redDiv'">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.workTimes.justWorkForToday)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(360 - item.calculatedValues.workTimes.justWorkForToday)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="column === 'dailyWork'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.workTimes.maxWorkWhenNightWorkPerformed)}}
                        </div>

                        <div style="text-align: center;">
                            <progress value="{{item.calculatedValues.workTimes.totalWorkToday}}"
                                max="{{item.calculatedValues.workTimes.maxWorkWhenNightWorkPerformed}}"></progress>
                        </div>

                        <div
                            [ngClass]="item.calculatedValues.workTimes.totalWorkToday < item.calculatedValues.workTimes.maxWorkWhenNightWorkPerformed ? 'simpleDiv' : 'redDiv'">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.workTimes.totalWorkToday)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.workTimes.maxWorkWhenNightWorkPerformed -
                                item.calculatedValues.workTimes.totalWorkToday)}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="column === 'weeklyWork'" style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.workTimes.workTimeWeeklyMax)}}
                        </div>

                        <div style="text-align: center;">
                            <progress value="{{item.calculatedValues.workTimes.workTimeWeekly}}"
                                max="{{item.calculatedValues.workTimes.workTimeWeeklyMax}}"></progress>
                        </div>

                        <div
                            [ngClass]="item.calculatedValues.workTimes.workTimeWeekly < item.calculatedValues.workTimes.workTimeWeeklyMax ? 'simpleDiv' : 'redDiv'">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.workTimes.workTimeWeekly)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.workTimes.workTimeWeeklyMax -
                                item.calculatedValues.workTimes.workTimeWeekly)}}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumn.length" style="background: #fafafa">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div style="width: 100%" [hidden]="!noDataText">
                        <div style="text-align: center; margin-top: 15px">
                            <span style="font-weight: bold;">{{"NO DATA IN THE CHOSEN PERIOD!" | translate}}</span>
                        </div>
                    </div>
                    <div style="width: 100%" [hidden]="!diagrammLoaded">
                        <div style="text-align: center; margin-top: 15px; display: ruby;">
                            <img src="assets/bed_colored.svg"
                                style="vertical-align: top; padding-bottom: 7px; height: 25px; margin-right: 15px">
                            <span style="padding-top: 25px">
                                {{'Rest' | translate}} - {{formatDurationFromMinutes(summaryRest)}}
                            </span>
                            <img src="assets/available_colored.svg"
                                style="vertical-align: top; padding-bottom: 7px; height: 25px; margin-right: 15px; margin-left: 10px">
                            <span style="padding-top: 25px">
                                {{'Available' | translate}} - {{formatDurationFromMinutes(summaryAvailable)}}
                            </span>
                            <img src="assets/work_colored.svg"
                                style="vertical-align: top; padding-bottom: 7px; height: 25px; margin-right: 15px; margin-left: 10px">
                            <span style="padding-top: 25px">
                                {{'Work' | translate}} - {{formatDurationFromMinutes(summaryWork)}}
                            </span>
                            <img src="assets/kormany_colored.svg"
                                style="vertical-align: top; padding-bottom: 7px; height: 25px; margin-right: 15px; margin-left: 10px">
                            <span style="padding-top: 25px">
                                {{'Drive' | translate}} - {{formatDurationFromMinutes(summaryDrive)}}
                            </span>
                        </div>
                        <div style="text-align: center; margin-top: 10px">
                            <span style="padding-top: 25px">
                                {{formatDateWithMonth(periodStart)}} - {{formatDateWithMonth(periodEnd)}}
                            </span>
                        </div>
                        <div style="text-align: center;">
                            <!--                            <span style="padding-top: 0px !important; margin: 0">-->
                            <!--&lt;!&ndash;                                <span style="font-size: small; font-weight: bold">{{'Last download: '}}</span>&ndash;&gt;-->
                            <!--                                <span style="font-size: small">{{formatDateWithMonth(element.uploadInformation.uploadTimeStamp)}}</span>-->
                            <!--                            </span>-->
                        </div>
                        <div id="new{{element.driverInfo.driverId?.toString()||''}}" style="width: 100%"></div>
                        <div id="{{element.driverInfo.driverId?.toString()||''}}" style="width: 100%"></div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumn;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>

<div style="display: none;" id="forprint">
    <div style="margin: 0 1rem 2rem;">
        <div style="display: inline-block; width: 100%;">
            <div style="float: left;" *ngIf="profile === 1"><img src="assets/images/tcd_logo_signin.jpg"
                    style="height: 50px; width: 150px;"></div>
            <div style="float: left;" *ngIf="profile === 0"><img src="assets/images/logo_blue.png"
                    style="height: 50px; width: 150px;"></div>
            <div style="font-size: 20px; color: #1B76BD; float: right; text-transform: uppercase; margin-top: 1rem;">
                {{'Drivers trace' | translate}}</div>
        </div>
        <hr style="width: 100%; border-top: 3px solid #1B76BD; margin-bottom: 2rem;">
    </div>
    <table *ngIf="traceResponseArray" style="width: 100%; margin-top: 2rem; border: none;">
        <thead style="border-bottom: 3px solid rgba(0,0,0,.3);">
            <tr style="font-size: 15px;">
                <th style="text-align: left; cursor: pointer;">
                    {{'Status' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Driver' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Continuous driving' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Daily driving' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Weekly driving' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Two weekly driving' | translate}}
                </th>
                <th style="text-align: left; min-width: 125px;">
                    {{'Rest' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Next work' | translate}}
                </th>
                <th style="text-align: left;">
                    {{'Note' | translate}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of traceResponseArray;" style="font-size: 12px;">
                <td style="vertical-align: middle;">
                    <div *ngIf="item.lastActivity.activity == 'AVAILABILITY'">
                        {{'AVAILABILITY' | translate}}
                    </div>
                    <div *ngIf="item.lastActivity.activity == 'DRIVING'">
                        {{'Driving' | translate}}
                    </div>
                    <div *ngIf="item.lastActivity.activity == 'WORK'">
                        {{'WORK' | translate}}
                    </div>
                    <div *ngIf="item.lastActivity.activity == 'BREAK/REST'">
                        {{'BREAK/REST' | translate}}
                    </div>
                </td>
                <td style="vertical-align: middle;">
                    <div>{{item.driverInfo.name}}</div>
                    <div>{{item.driverInfo.cardNumber}}</div>
                </td>
                <td style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none">{{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining > 30">
                            <div style="text-align: center;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining < 30"
                            style="color: #ee2921 !important;">
                            <div style="text-align: center;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax)}}
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <progress
                                value="{{item.calculatedValues.drivingContinuousMax - item.calculatedValues.drivingContinuousRemaining}}"
                                max="{{item.calculatedValues.drivingContinuousMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining > 30">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax -
                                item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingContinuousRemaining < 30"
                            style="color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousMax -
                                item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingContinuousRemaining)}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingDailyRemaining)}}</div>
                        <div *ngIf="600 - item.calculatedValues.drivingDailyRemaining < 540"
                            style="text-align: center;">
                            {{formatTime(600)}}
                        </div>
                        <div *ngIf="600 - item.calculatedValues.drivingDailyRemaining > 540"
                            style="color: #ee2921 !important; text-align: center;">
                            {{formatTime(600)}}
                        </div>
                        <div style="text-align: center;">
                            <progress value="{{600 - item.calculatedValues.drivingDailyRemaining}}"
                                max="{{600}}"></progress>
                        </div>
                        <div *ngIf="600 - item.calculatedValues.drivingDailyRemaining < 540">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(600 - item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="600 - item.calculatedValues.drivingDailyRemaining > 540"
                            style="color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">
                                {{formatTime(600 - item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingDailyRemaining)}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="vertical-align: middle; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}</div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining < 500"
                            style="font-size: 12px; text-align: center; color: #ee2921 !important;">
                            {{formatTime(item.calculatedValues.drivingWeeklyMax)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining > 500"
                            style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.drivingWeeklyMax)}}
                        </div>
                        <div style="text-align: center;">
                            <progress value="{{item.drivingWeek}}"
                                max="{{item.calculatedValues.drivingWeeklyMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining < 500"
                            style="font-size: 12px; color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingWeek)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingWeeklyRemaining > 500" style="font-size: 12px;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingWeek)}}</div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingWeeklyRemaining)}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="vertical-align: middle; width: 50px; font-size: 12px;">
                    <div style="float: left;">
                        <div style="display: none;">{{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining < 1000"
                            style="font-size: 12px; text-align: center; color: #ee2921 !important;">
                            {{formatTime(item.calculatedValues.drivingTwoWeeklyMax)}}
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining > 1000"
                            style="font-size: 12px; text-align: center;">
                            {{formatTime(item.calculatedValues.drivingTwoWeeklyMax)}}
                        </div>
                        <div style="text-align: center;">
                            <progress value="{{item.drivingLast2Weeks}}"
                                max="{{item.calculatedValues.drivingTwoWeeklyMax}}"></progress>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining < 1000"
                            style="font-size: 12px; color: #ee2921 !important;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingLast2Weeks)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                            </div>
                        </div>
                        <div *ngIf="item.calculatedValues.drivingTwoWeeklyRemaining > 1000" style="font-size: 12px;">
                            <div style="float: left; display: inline-block;">{{formatTime(item.drivingLast2Weeks)}}
                            </div>
                            <div style="float: right; display: inline-block;">
                                {{formatTime(item.calculatedValues.drivingTwoWeeklyRemaining)}}
                            </div>
                        </div>
                    </div>
                </td>
                <td style="vertical-align: middle; font-size: 12px; min-width: 15%;">
                    <div *ngIf="item.restLastDaily">
                        {{'Daily' | translate}}: {{formatDateWithoutDay(item.restLastDaily.restStart)}} -
                        {{formatDateWithoutDay(item.restLastDaily.restEnd)}}
                        ({{formatDuration(item.restLastDaily.restEnd - item.restLastDaily.restStart)}})
                    </div>
                    <div *ngIf="item.restLastDaily">
                        {{'Weekly' | translate}}: {{formatDateWithoutDay(item.restLastWeekly1.start)}} -
                        {{formatDateWithoutDay(item.restLastWeekly1.end)}} ({{formatDuration(item.restLastWeekly1.end -
                        item.restLastWeekly1.start)}})
                    </div>
                </td>
                <td style="vertical-align: middle; font-size: 12px;">
                    <div>{{'Start' | translate}}:
                        {{formatDateWithoutYear(item.calculatedValues.projections.nextWorkStart)}}</div>
                    <div>{{'Length' | translate}}: {{formatTime(item.calculatedValues.projections.nextWorkLength)}}
                    </div>
                </td>
                <td style="vertical-align: middle; font-size: 12px;">
                    <div *ngIf="item.calculatedValues.projections.nextWorkComments.length > 0">
                        {{item.calculatedValues.projections.nextWorkComments}}
                    </div>
                    <div *ngIf="item.calculatedValues.projections.nextWorkComments.length == 0">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>