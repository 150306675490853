import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { PrintService } from 'app/services/rszoft/print.service';
import { TabOption } from 'app/services/rszoft/report.model';
import {
    DriverActivity,
    ReportContext,
    RowType,
    VehicleActivity
} from 'app/services/rszoft/data-match/models/context.model';
import * as moment from 'moment-timezone';
import { DataMatchService } from 'app/services/rszoft/data-match/data-match.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateUtil } from '../../utils/date.util';
import { getCurrentTimezone } from 'app/moment.override';

@Component({
    selector: 'app-data-match-print',
    templateUrl: './data-match-print.component.html',
    styleUrls: ['../data-match.component.css']
})
export class DataMatchPrintComponent implements OnInit, AfterViewInit {
    tab: TabOption;
    reportContext: ReportContext;

    _moment = moment;
    tabOptions = TabOption;
    rowTypes = RowType;
    dateUtil = DateUtil;

    // Table configuration
    displayDriverColumns = ['startDate', 'endDate', 'vehicle', 'difference', 'kmRange', 'kmSum'];
    displayVehicleColumns = ['startDate', 'endDate', 'driver', 'drive', 'work', 'availability', 'kmRange', 'kmSum'];

    constructor(public printService: PrintService,
                private dataMatchService: DataMatchService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        const tab = this.activatedRoute.snapshot.paramMap.get('tab');
        this.tab = TabOption[tab];
        if (!this.tab) {
            console.log('No TAB info, which chart to print?');
            return;
        }

        const labelledReportContext = this.dataMatchService.getReportContext(this.tab);
        if (!labelledReportContext || !labelledReportContext.reportContext) {
            return;
        }
        this.reportContext = labelledReportContext.reportContext;
    }

    ngAfterViewInit() {
        const format = 'YYYY-MM-DD';
        const startDate = moment(this.reportContext.metaData.periodStartDate).format(format);
        const endDate = moment(this.reportContext.metaData.periodEndDate).format(format);
        const timezone = getCurrentTimezone();
        let entityOrCompany = this.reportContext.metaData.companyName?.replaceAll(' ', '_') || 'Company_wide';
        if (this.reportContext.data?.length === 1) {
            if (this.tab === TabOption.DRIVER) {
                const driverData = this.reportContext.data[0] as DriverActivity;
                entityOrCompany = driverData.surname.toUpperCase() + '_' + driverData.firstNames.replaceAll(' ', '_');
            } else {
                const vehicleData = this.reportContext.data[0] as VehicleActivity;
                entityOrCompany = vehicleData.registrationNumber.replaceAll(' ', '_');
            }
        }

        const title = document.title;
        document.title = [startDate, endDate, 'Data', 'match', this.tab.toLowerCase(), entityOrCompany, timezone].join('_');
        setTimeout(() => {
            window.print();
            document.title = title;
        }, 500);
    }

    @HostListener('window:afterprint', [])
    onWindowAfterPrint() {
        this.navigateBackToReport();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_) {
        this.navigateBackToReport();
    }

    navigateBackToReport() {
        this.router.navigate(['/report/data-match', {reloadResultList: true}]);
    }

}
