<div class="data-match-container print" style="min-width: 60rem">
    <div class="data-match-content" *ngIf="!!reportContext?.data">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
        <div style="margin-bottom: 2rem">
            <ng-container [ngTemplateOutlet]="tab === tabOptions.DRIVER ? driverTableTemplate : vehicleTableTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #headerTemplate>
    <div class="card-container" style="margin-bottom: 20px">
        <div class="card-margin print">
            <div class="header-row">
                <div class="header-column-sm">{{ 'Report.General.Period' | instantTranslate:reportContext.metaData.language | async }}</div>
                <div class="header-column-lg">
                    {{ _moment(reportContext.metaData.periodStartDate).format('Report.General.DateFormat' | instantTranslate:reportContext.metaData.language | async) }}&nbsp;-
                    {{ _moment(reportContext.metaData.periodEndDate).format('Report.General.DateFormat' | instantTranslate:reportContext.metaData.language | async) }}
                </div>
            </div>
            <div class="header-row">
                <div class="header-column-sm">{{ 'Report.General.Generated' | instantTranslate:reportContext.metaData.language | async }}</div>
                <div class="header-column-lg">
                    {{ _moment(reportContext.metaData.made).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) }}
                </div>
            </div>
            <div class="header-row">
                <div class="header-column-sm">{{ 'Report.General.Timezone' | instantTranslate:reportContext.metaData.language | async }}</div>
                <div class="header-column-lg">
                    {{ 'timezones.' + reportContext.metaData.timezone | instantTranslate:reportContext.metaData.language | async }}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #driverTableTemplate>
    <div class="table-container table-container--driver">
        <div class="table-margin print">
            <table mat-table [dataSource]="reportContext.data" class="svt-table alternate-rows">
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        <div>
                            {{ 'Report.General.StartDate' | instantTranslate:reportContext.metaData.language | async }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.SUMMARY ? 5 : 1">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE" style="font-weight: bold">
                            {{ dataMatchData.surname.toUpperCase() }}
                            {{ dataMatchData.firstNames }}
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL">
                            {{ !!dataMatchData.startDate ? _moment(dataMatchData.startDate).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) : '-' }}
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.SUMMARY">
                            {{ ('Report.DataMatch.TotalCases' | instantTranslate:reportContext.metaData.language | async).replace('%', dataMatchData.caseCount) }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.EndDate' | instantTranslate:reportContext.metaData.language | async }}</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE ? 5 : 1"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.SUMMARY}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE && !!dataMatchData.globalError">
                            <div class="icon-cell">
                                <mat-icon class="icon icon--error icon--top">error_outline</mat-icon>
                                <span>
                                    <span>
                                        {{ ('Report.DataMatch.ErrorCode.' + dataMatchData.globalError) | instantTranslate:reportContext.metaData.language | async }}
                                    </span>&nbsp;
                                    <span *ngIf="!!dataMatchData.lastDownload">
                                        {{ 'Report.General.LastDownloadDate' | instantTranslate:reportContext.metaData.language | async }}:
                                        {{ _moment(dataMatchData.lastDownload).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE && !!dataMatchData.allDownloaded">
                            <div class="icon-cell">
                                <mat-icon class="icon icon--ok icon--stroked"
                                          svgIcon="data_available_outline_icon"
                                          style="transform: scale(0.9) translateY(-2px)"></mat-icon>
                                <span>
                                    {{ 'Report.DataMatch.AllTachographDataDownloaded' | instantTranslate:reportContext.metaData.language | async }}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL">
                            {{ !!dataMatchData.endDate ? _moment(dataMatchData.endDate).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) : '-' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.Vehicle' | instantTranslate:reportContext.metaData.language | async }}</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType !== rowTypes.DETAIL}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL">
                            {{ dataMatchData.vehicle }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="difference">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.DataMatch.Difference' | instantTranslate:reportContext.metaData.language | async }}</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType !== rowTypes.DETAIL}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.differenceCode">
                            <div class="icon-cell">
                                <mat-icon class="icon icon--error icon--top">error_outline</mat-icon>
                                <span>
                                    <span>
                                        {{ ('Report.DataMatch.ErrorCode.' + dataMatchData.differenceCode) | instantTranslate:reportContext.metaData.language | async }}
                                    </span>&nbsp;
                                    <span *ngIf="!!dataMatchData.lastDownload">
                                        {{ 'Report.General.LastDownloadDate' | instantTranslate:reportContext.metaData.language | async }}:
                                        {{ _moment(dataMatchData.lastDownload).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmRange">
                    <th mat-header-cell *matHeaderCellDef>km</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType !== rowTypes.DETAIL}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.odometerBegin && !!dataMatchData.odometerEnd">
                            {{ dataMatchData.odometerBegin | number:'':'hu' }}&nbsp;-
                            {{ dataMatchData.odometerEnd | number:'':'hu' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmSum">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell" style="justify-content: flex-end">
                            <span>&sum;</span>&nbsp;
                            <span>km</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.DRIVER_VEHICLE}">
                        <div *ngIf="dataMatchData.rowType !== rowTypes.DRIVER_VEHICLE" class="icon-cell" style="justify-content: flex-end">
                            <span *ngIf="dataMatchData.odometerSum >= 0" class="icon"></span>
                            <mat-icon *ngIf="dataMatchData.odometerSum < 0" class="icon icon--error">error_outline</mat-icon>
                            <span class="number-wrapper">{{ dataMatchData.odometerSum | number:'':'hu' }}</span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayDriverColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayDriverColumns"
                    [ngClass]="{'divider': row.rowType === rowTypes.SUMMARY}"></tr>
            </table>
            <!--            <app-data-match-paginator [length]="rowCountPerEntity.length"-->
            <!--                                      [pageSizeOptions]="pageSizeOptions"-->
            <!--                                      [actualPageSize]="actualPageSize"-->
            <!--                                      [actualPageIndex]="actualPageIndex"-->
            <!--                                      (onPaginationChanged)="onPaginationChange($event)"></app-data-match-paginator>-->
        </div>
    </div>
</ng-template>

<ng-template #vehicleTableTemplate>
    <div class="table-container table-container--vehicle">
        <div class="table-margin print">
            <table mat-table [dataSource]="reportContext.data" class="svt-table alternate-rows">
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        <div>
                            {{ 'Report.General.StartDate' | instantTranslate:reportContext.metaData.language | async }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.SUMMARY ? 3 : 1"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE" style="font-weight: bold">
                            <span>{{ dataMatchData.registrationNumber }}</span>&nbsp;
                            <span *ngIf="dataMatchData.registrationNation">({{ dataMatchData.registrationNation }})</span>
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL">
                            {{ !!dataMatchData.startDate ? _moment(dataMatchData.startDate).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) : '-' }}
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.SUMMARY">
                            {{ ('Report.DataMatch.TotalCases' | instantTranslate:reportContext.metaData.language | async).replace('%', dataMatchData.caseCount) }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Report.General.EndDate' | instantTranslate:reportContext.metaData.language | async }}</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE ? 7 : 1"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.SUMMARY}"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE && !!dataMatchData.allDownloaded">
                            <div class="icon-cell">
                                <mat-icon class="icon icon--ok icon--top icon--stroked"
                                          svgIcon="data_available_outline_icon"
                                          style="transform: scale(0.9) translateY(-2px)"></mat-icon>
                                <span>
                                    {{ 'Report.DataMatch.NoActivityWithoutCard' | instantTranslate:reportContext.metaData.language | async }}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DRIVER_VEHICLE && !!dataMatchData.globalError">
                            <div class="icon-cell">
                                <mat-icon class="icon icon--error icon--top">error_outline</mat-icon>
                                <span>
                                    <span>
                                        {{ ('Report.DataMatch.ErrorCode.' + dataMatchData.globalError) | instantTranslate:reportContext.metaData.language | async }}
                                    </span>&nbsp;
                                    <span *ngIf="!!dataMatchData.lastDownload">
                                        {{ 'Report.General.LastDownloadDate' | instantTranslate:reportContext.metaData.language | async }}:
                                        {{ _moment(dataMatchData.lastDownload).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL">
                            {{ !!dataMatchData.endDate ? _moment(dataMatchData.endDate).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) : '-' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <div class="icon">&nbsp;</div>
                            {{ 'Report.General.Driver' | instantTranslate:reportContext.metaData.language | async }}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload ? 6 : 1"
                        [ngClass]="{'hidden': dataMatchData.rowType !== rowTypes.DETAIL}"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode" style="margin-left: 23px">
                            {{ dataMatchData.driver }}
                        </div>
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.differenceCode">
                            <div class="icon-cell">
                                <span *ngIf="!!dataMatchData.driver" style="align-self: flex-start; margin-left: 23px; margin-right: 5px">{{ dataMatchData.driver }}</span>
                                <mat-icon class="icon icon--error icon--top">error_outline</mat-icon>
                                <span>
                                    <span>
                                        {{ ('Report.DataMatch.ErrorCode.' + dataMatchData.differenceCode) | instantTranslate:reportContext.metaData.language | async }}
                                    </span>&nbsp;
                                    <span *ngIf="!!dataMatchData.lastDownload">
                                        {{ 'Report.General.LastDownloadDate' | instantTranslate:reportContext.metaData.language | async }}:
                                        {{ _moment(dataMatchData.lastDownload).myFormat('Report.General.TimeFormat' | instantTranslate:reportContext.metaData.language | async) }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="drive">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="kormany" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.DRIVER_VEHICLE || dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload}"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="!!dataMatchData.drive || dataMatchData.rowType === rowTypes.SUMMARY">
                            {{ dateUtil.transform(dataMatchData.drive || 0, true, true) }}
                        </div>
                        <div *ngIf="!dataMatchData.drive && dataMatchData.rowType !== rowTypes.SUMMARY">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="work">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="work" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.DRIVER_VEHICLE || dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload}"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="!!dataMatchData.work || dataMatchData.rowType === rowTypes.SUMMARY">
                            {{ dateUtil.transform(dataMatchData.work || 0, true, true) }}
                        </div>
                        <div *ngIf="!dataMatchData.work && dataMatchData.rowType !== rowTypes.SUMMARY">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="availability">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell">
                            <mat-icon svgIcon="available" class="icon"></mat-icon>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [attr.colspan]="dataMatchData.rowType === rowTypes.SUMMARY ? 2 : 1"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.DRIVER_VEHICLE || dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload}"
                        [ngStyle]="{'color': dataMatchData.rowType === rowTypes.DETAIL && !dataMatchData.differenceCode ? 'inherit' : ''}">
                        <div *ngIf="!!dataMatchData.availability || dataMatchData.rowType === rowTypes.SUMMARY">
                            {{ dateUtil.transform(dataMatchData.availability || 0, true, true) }}
                        </div>
                        <div *ngIf="!dataMatchData.availability && dataMatchData.rowType !== rowTypes.SUMMARY">-</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmRange">
                    <th mat-header-cell *matHeaderCellDef>km</th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType !== rowTypes.DETAIL || dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload}">
                        <div *ngIf="dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.odometerBegin && !!dataMatchData.odometerEnd">
                            {{ dataMatchData.odometerBegin | number:'':'hu' }}&nbsp;-
                            {{ dataMatchData.odometerEnd | number:'':'hu' }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="kmSum">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="icon-cell" style="justify-content: flex-end">
                            <span>&sum;</span>&nbsp;
                            <span style="margin-right: 4px">km</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let dataMatchData"
                        [ngClass]="{'hidden': dataMatchData.rowType === rowTypes.DRIVER_VEHICLE || dataMatchData.rowType === rowTypes.DETAIL && !!dataMatchData.lastDownload}">
                        <div *ngIf="dataMatchData.rowType !== rowTypes.DRIVER_VEHICLE" class="icon-cell" style="justify-content: flex-end">
                            <span *ngIf="dataMatchData.odometerSum >= 0" class="icon"></span>
                            <mat-icon *ngIf="dataMatchData.odometerSum < 0" class="icon icon--error">error_outline</mat-icon>
                            <span class="number-wrapper">{{ dataMatchData.odometerSum | number:'':'hu' }}</span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayVehicleColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayVehicleColumns"
                    [ngClass]="{'divider': row.rowType === rowTypes.SUMMARY}"
                    [ngStyle]="{'color': row.rowType === rowTypes.DETAIL && !row.differenceCode ? 'rgba(0,0,0,.45)' : 'rgba(0,0,0,.87)'}"></tr>
            </table>
            <!--            <app-data-match-paginator [length]="rowCountPerEntity.length"-->
            <!--                                      [pageSizeOptions]="pageSizeOptions"-->
            <!--                                      [actualPageSize]="actualPageSize"-->
            <!--                                      [actualPageIndex]="actualPageIndex"-->
            <!--                                      (onPaginationChanged)="onPaginationChange($event)"></app-data-match-paginator>-->
        </div>
    </div>
</ng-template>
