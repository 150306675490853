import { Component, OnInit } from '@angular/core';
import { AdminPanelService } from 'app/services/admin-panel.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {

  currentTaskInQueue = 0;

  refreshInterval: any;

  loading = false;

  billingYear = new Date().getFullYear();
  billingQuarter = 1;

  reloadFileHash = '';

  constructor(
    private readonly adminPanelService: AdminPanelService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.refreshStat();
    this.refreshInterval = setInterval(() => {
      this.refreshStat();
    }, 10000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  refreshStat(){
    this.adminPanelService.generateInfringementsForCompanyAndAllSubCompaniesStat()
      .subscribe((response) => {
        this.currentTaskInQueue = response.queueSize;
      });
  }

  cancelRegenerateInfringements(){
    this.adminPanelService.generateInfringementsForCompanyAndAllSubCompaniesCancel()
      .subscribe(() => {
        this.refreshStat();
      })
  }

  regenerateInfringements(){
    this.loading = true;
    const companyId = this.authService.currentCompanyIdValue();
    this.adminPanelService.generateInfringementsForCompanyAndAllSubCompanies(
      companyId
    ).subscribe(() => {
      this.refreshStat();
      this.loading = false;
    })
  }

  calculateBilling(){
    this.adminPanelService.calculateBills(this.billingYear, this.billingQuarter).subscribe(() => {
      // this.billingYear = new Date().getFullYear();
      // this.billingQuarter= 1;
    });
  }

  reloadDataForFile(){
    this.adminPanelService.reloadDataForFile(this.reloadFileHash).subscribe(() => {
      this.reloadFileHash = '';
    });
  }


}
