import {Menu} from './menu.model';

export const menuItems = [
    new Menu(1, 'Dashboard.Title', '/dashboard', null, 'dashboard', null, false, 0),
    new Menu(2, 'Report.Title', '/report', null, 'dvr', null, false, 0),
    new Menu(3, 'Drivers', '/drivers', null, 'people', null, false, 0),
    new Menu(4, 'Vehicles', '/vehicles', null, 'local_shipping', null, false, 0),
    new Menu(5, 'Devices', '/devices', null, 'perm_device_information', null, false, 0),
    new Menu(6, 'Upload', '/upload', null, 'cloud_upload', null, false, 0),
    new Menu(7, 'Uploaded files', '/uploaded-files', null, 'file_copy', null, true, 0),
    new Menu(8, 'Cards', '/uploaded-files/uploadeddriverfiles', null, 'people', null, false, 7),
    new Menu(9, 'Tachographs', '/uploaded-files/uploadedvehiclefiles', null, 'local_shipping', null, false, 7),
    new Menu(10, 'Connected services', '/settings/connected-services', null, 'cloud_circle', null, false, 0),
    new Menu(11, 'Tacho Live', '/tacholive', null, 'my_location', null, false, 0, false, false, false ),
    new Menu(12, 'Notifications', '/notificationManager', null, 'notifications_none', null, false, 0),
    new Menu(13, 'Manage sub company', '/manage-sub-company', null, 'warehouse', null, false, 0, false, false, false, true ),
    // new Menu(14, 'WhatsNew', '/release', null, 'fiber_new', null, false, 0, false, false, true),
    new Menu(15, 'Subscription', '/subscription', null, 'subscriptions', null, false, 0, false, true),
    new Menu(16, 'Updater checker', '/updaterchecker', null, 'update', null, false, 0, false, false,false, false, true),
    new Menu(16, 'Admin panel', '/admin', null, 'update', null, false, 0, false, false,false, false, true),
];

// export const verticalMenuItems = [
//     new Menu(1, 'Dashboard.Title', '/dashboard', null, 'dashboard', null, false, 0),
//     new Menu(2, 'Drivers', '/drivers', null, 'people', null, false, 0),
//     new Menu(3, 'Vehicles', '/vehicles', null, 'local_shipping', null, false, 0),
//     new Menu(4, 'Driver view', '/individualview', null, 'person', null, false, 27),
//     new Menu(5, 'Vehicle view', '/vehicleview', null, 'local_shipping', null, false, 27),
//     // new Menu(6, 'Analysis', '/analysis', null, 'dvr', null, true, 0, ),
//     // new Menu(7, 'Infringements', '/analysis/infringements', null, 'not_interested', null, false, 27),
//     // new Menu(8, 'Work hours', '/analysis/workhours', null, 'access_time', null, false, 6 ),
//     // new Menu(9, 'Vehicle report', '/analysis/vehiclereport', null, 'local_shipping', null, false, 6),
//     // new Menu(10, 'Tacho Live', '/tacholive', null, 'my_location', null, false, 0),
//     new Menu(11, 'Upload', '/upload', null, 'cloud_upload', null, false, 0),
//     new Menu(12, 'Uploaded files', '/uploaded-files', null, 'file_copy', null, true, 0),
//     new Menu(13, 'Cards', '/uploaded-files/uploadeddriverfiles', null, 'people', null, false, 12),
//     new Menu(14, 'Tachographs', '/uploaded-files/uploadedvehiclefiles', null, 'local_shipping', null, false, 12),
//     new Menu(15, 'Settings.Title', '/settings', null, 'settings', null, true, 0),
//     new Menu(16, 'Driver notifications', '/settings/driverspanel', null, 'notification_important', null, false, 27),
//     new Menu(17, 'Vehicle notifications', '/settings/vehiclespanel', null, 'notification_important', null, false, 27),
//     new Menu(18, 'Create user', '/settings/createuser', null, 'person_add', null, false, 15, true),
//     new Menu(19, 'Create sub company', '/settings/registerSubCompany', null, 'note_add', null, false, 15, true),
//     new Menu(20, 'Change password', '/settings/changepassword', null, 'vpn_key', null, false, 15),
//     new Menu(21, 'Change credentials', '/settings/changecredentials', null, 'folder_shared', null, false, 15),
//     new Menu(22, 'Connected services', '/settings/connected-services', null, 'cloud_circle', null, false, 15),
//     new Menu(23, 'T4S Devices', '/devices', null, 'perm_device_information', null, false, 15),
//     // new Menu(22, 'Language', '/settings/language', null, 'language', null, false, 15),
//     new Menu(24, 'Client settings', '/settings/clientSettings', null, 'business', null, false, 15),
//     // new Menu(25, 'Reports', '/analysis/reports', null, 'picture_as_pdf', null, false, 6, false, false),
//     // new Menu(26, 'Expenses', '/expenses', null, 'euro_symbol', null, false, 0, false, false),
//     new Menu(27, 'Under development', '/experimental', null, 'star_border_purple500', null, true, 0),
//     new Menu(28, 'Report.Title', '/report', null, 'dvr', null, false, 0),
//     new Menu(29, 'Daily chart', '/report/dailychart', null, 'route_report_icon', null, false, 28),
//     new Menu(30, 'Tacho Live', '/tacholive', null, 'my_location', null, false, 0, false, false, false ),
//     new Menu(32, 'Updater checker', '/updaterchecker', null, 'fact_check', null, false, 0, false, false, false ),
//     // new Menu(29, 'Daily chart', '/report/dailychart', null, 'route_report_icon', null, false, 28),
//     new Menu(33, 'Notifications', '/notificationManager', null, 'notifications_none', null, false, 0),
//     new Menu(31, 'WhatsNew', '/release', null, 'fiber_new', null, false, 0, false, false, true),
//     new Menu(34, 'Subscription', '/subscription', null, 'subscriptions', null, false, 0, false, true),
//     new Menu(35, 'Manage sub company', '/manage-sub-company', null, 'warehouse', null, false, 0, false, false, false ),
// ];


// export const verticalMenuItemsTCD = [
//     new Menu(1, 'Dashboard.Title', '/dashboard', null, 'dashboard', null, false, 0),
//     new Menu(2, 'Drivers', '/drivers', null, 'people', null, false, 0),
//     new Menu(3, 'Vehicles', '/vehicles', null, 'local_shipping', null, false, 0),
//     new Menu(4, 'Driver view', '/individualview', null, 'person', null, false, 0),
//     new Menu(5, 'Vehicle view', '/vehicleview', null, 'local_shipping', null, false, 0),
//     new Menu(6, 'Analysis', '/analysis', null, 'dvr', null, true, 0, ),
//     new Menu(7, 'Infringements', '/analysis/infringements', null, 'not_interested', null, false, 6),
//     new Menu(8, 'Work hours', '/analysis/workhours', null, 'access_time', null, false, 6 ),
//     new Menu(9, 'Vehicle report', '/analysis/vehiclereport', null, 'local_shipping', null, false, 6),
//     new Menu(10, 'Drivers trace', '/tacholive', null, 'my_location', null, false, 0),
//     new Menu(11, 'Upload', '/upload', null, 'cloud_upload', null, false, 0),
//     new Menu(12, 'Uploaded files', '/uploaded-files', null, 'file_copy', null, true, 0),
//     new Menu(13, 'Cards', '/uploaded-files/uploadeddriverfiles', null, 'people', null, false, 12),
//     new Menu(14, 'Tachographs', '/uploaded-files/uploadedvehiclefiles', null, 'commute', null, false, 12),
//     new Menu(15, 'Settings.Title', '/settings', null, 'settings', null, true, 0),
//     new Menu(16, 'Driver notifications', '/settings/driverspanel', null, 'notification_important', null, false, 15),
//     new Menu(17, 'Vehicle notifications', '/settings/vehiclespanel', null, 'notification_important', null, false, 15),
//     new Menu(18, 'Create user', '/settings/createuser', null, 'person_add', null, false, 15, true),
//     new Menu(19, 'Create sub company', '/settings/registerSubCompany', null, 'note_add', null, false, 15, true),
//     new Menu(20, 'Change password', '/settings/changepassword', null, 'vpn_key', null, false, 15),
//     new Menu(21, 'Change credentials', '/settings/changecredentials', null, 'folder_shared', null, false, 15),
//     new Menu(22, 'Connected services', '/settings/connected-services', null, 'cloud_circle', null, false, 15),
//     new Menu(23, 'T4S Devices', '/devices', null, 'perm_device_information', null, false, 15),
//     // new Menu(22, 'Language', '/settings/language', null, 'language', null, false, 15),
//     new Menu(24, 'Client settings', '/settings/clientSettings', null, 'business', null, false, 15),
//     // new Menu(25, 'Reports', '/analysis/reports', null, 'picture_as_pdf', null, false, 6, false, false),
//     // new Menu(26, 'Expenses', '/expenses', null, 'euro_symbol', null, false, 0, false, false),
//     // new Menu(27, 'Under development', '/experimental', null, 'star_border_purple500', null, true, 0),
//     // new Menu(28, 'Report.Title', '/report', null, 'dvr', null, true, 0),
//     // new Menu(29, 'Daily chart', '/report/dailychart', null, 'route_report_icon', null, false, 28)
//     new Menu(33, 'Notifications', '/notificationManager', null, 'notifications_none', null, false, 15),
//     new Menu(31, 'WhatsNew', '/release', null, 'fiber_new', null, false, 0, false, false, true),
//     new Menu(34, 'Subscription', '/subscription', null, 'subscriptions', null, false, 0, false, true),
// ];

// export const horizontalMenuItems = [
//     new Menu(1, 'Dashboard.Title', '/', null, 'dashboard', null, false, 0),
//     new Menu(2, 'Users', '/users', null, 'supervisor_account', null, false, 0),
// ];
