import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { Chart } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import { TranslateService } from '@ngx-translate/core';
import moment from 'app/moment.override';
import { Moment } from 'moment-timezone';
import { DateUtil } from '../../utils/date.util';
import { DailyChartService } from 'app/services/rszoft/dailychart/daily-chart.service';
import {
    COLOR_AVAILABLE,
    COLOR_CHART_GREY,
    COLOR_CREW_DRIVING,
    COLOR_DRIVE,
    COLOR_INFRINGMENT,
    COLOR_REST,
    COLOR_WORK,
    COLOR_WORKING_TIME
} from 'app/helpers/globals';
import { ChartAnnotationType } from './bordercross-component/bordercross-component.component';
import { CharttranslationService } from 'app/services/rszoft/charttranslation.service';
import {
    Dailychartinput,
    DataSet, DataTypes,
    InfoLineitemDescription,
    Offence, OneWorkingTimeRow
} from 'app/services/rszoft/dailychart/models/chart.model';
import { Subscription } from 'rxjs';
import { TabOption } from 'app/services/rszoft/report.model';
import { PrintService } from 'app/services/rszoft/print.service';

exporting(Highcharts);

const Annotations = require('highcharts/modules/annotations');
const More = require('highcharts/highcharts-more');
Annotations(Highcharts);
More(Highcharts);
const { v4: uuidv4 } = require('uuid');
const SERIES_TYPE_AREA =  'area';
const SERIES_TYPE_AREARANGE =  'arearange';
const XAXIS_OFFSET_NORMAL = -29;
const XAXIS_OFFSET_NORMAL_SHIFT = 22;
const XAXIS_OFFSET_PRINT = -19;
const XAXIS_OFFSET_PRINT_SHIFT = 17;
const XAXIS_LABEL_OFFSET_NORMAL = 42;
const XAXIS_LABEL_OFFSET_NORMAL_SHIFT = 20;
const XAXIS_LABEL_OFFSET_PRINT = 30;
const CHART_HEIGHT_NORMAL = 150;
const CHART_HEIGHT_PRINT = 100;
const CHART_HEIGHT_NORMAL_SHIFT = 20;
const WORKING_TIME_DATA_START_VAlUE_NORMAL = 0;
const WORKING_TIME_DATA_START_VAlUE_CREW = -1;

@Component({
  selector: 'app-dailychart',
  templateUrl: './dailychart.component.html',
  styleUrls: ['./dailychart.component.css']
})
export class DailychartComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() dailyChartInput: Dailychartinput;
    @Input() currentLang: string;
    @Input() indexOfChart: number;
    @Input() responseIndex: number;
    @Input() languageChanged: EventEmitter<string>;
    @Input() isPrinting: boolean;
    @Input() typeOfChart: TabOption;

    @Output() chartLoaded = new EventEmitter<any>();
    @Output() highChartObjectCreated = new EventEmitter<Highcharts.Chart>();

    highChartObject: Highcharts.Chart;

    elementId: string;
    // annotation = [];
    chartDataChangedSubscription: Subscription;

    chartAnnotationTypes = ChartAnnotationType;
    startDayInMoment: Moment;
    nameOfDayInWeek: string;

    firstRendered: boolean;
    viewInitiated: boolean;
    dataInitiated: boolean;
    timeOffsetChangingPlus: boolean;
    timeOffsetChangingMinus: boolean;

    timezone: string;
    dateUtil = DateUtil;
    _moment = moment;
    math = Math;

    dateFormat = '%Y-%m-%d';

    xAxisOffset = XAXIS_OFFSET_NORMAL;
    xAxislabelOffset = XAXIS_LABEL_OFFSET_NORMAL;

    chartHeight = CHART_HEIGHT_NORMAL;

    workingTimeDataValueStart = WORKING_TIME_DATA_START_VAlUE_NORMAL;

    infringementDisplayedColumns: string[] = ['staticInfringmentsText', 'offenceInterval', 'typeOfOffence', 'offenceTypeDescription'];
    offencesDisplayedColumns: string[] = [
        'staticInfringmentsText', 'offenceInterval', 'typeOfOffence', 'offenceTypeDescription',
        'duration', 'norm', 'difference', 'mi', 'si', 'vsi', 'msi'
    ];
    faultsDisplayedColumns = ['icon', 'label', 'start', 'end', 'duration', 'withoutDriverCardIcon', 'description'];
    groupedInfrigments: Offence[][];

    tabOptions = TabOption;

    softMin: number;

    lastDownloadInfolineDescription: InfoLineitemDescription;

    @ViewChild('borderCrossingContainer') borderCrossingContainer;

    constructor(private translate: TranslateService,
                private ngZone: NgZone,
                private chartTranslationService: CharttranslationService,
                public dailyChartService: DailyChartService,
                public printService: PrintService) {
        this.firstRendered = false;
        this.viewInitiated = false;
        this.dataInitiated = false;
    }

    ngOnInit(): void {
        this.chartDataChangedSubscription = this.dailyChartService.getReportContext(this.typeOfChart).reportContext.onDatasSetChanged()
            .subscribe(() => {
                this.createChart();
            });
        if (this.dailyChartInput) {
            this.elementId = this.generateUniqueId();
            this.timezone = this.dailyChartInput.timezone;
            this.calculateInfolinePositions();
            this.checkForTimeOffsetChange();
            this.startDayInMoment = moment(this.dailyChartInput.dateOfChart);
            this.startDayInMoment.tz(this.timezone);
            this.setDayOfWeekOfChart();
            // this.languageChanged.subscribe(currentLang => {
            //     this.currentLang = currentLang;
            //     this.setDayOfWeekOfChart();
            // });

            if (this.timezone === undefined) {
                console.log('No timezone set, initiated to CET');
                this.timezone = 'CET';
            }

            this.groupedInfrigments = this.createGroupedInfringments(this.dailyChartInput.offences);

            this.initChartDimensions();

            if (this.isCrewDrivingToday()) {
                this.workingTimeDataValueStart = WORKING_TIME_DATA_START_VAlUE_CREW;
            }

            this.chartTranslationService.getTextByLanguageAndValue('Report.DailyChart.dateFormatHighChart', this.currentLang).subscribe(value => {
                this.dateFormat = value;
                this.dataInitiated  = true;
            })
            this.dailyChartService.onMenuChange().subscribe(() => {
                this.highChartObject.reflow();
            })
        }
    }

    private initChartDimensions() {
        this.xAxisOffset = this.isPrinting ? XAXIS_OFFSET_PRINT : XAXIS_OFFSET_NORMAL;
        this.xAxislabelOffset = this.isPrinting ? XAXIS_LABEL_OFFSET_PRINT : XAXIS_LABEL_OFFSET_NORMAL;
        this.chartHeight = this.isPrinting ? CHART_HEIGHT_PRINT : CHART_HEIGHT_NORMAL;
        this.softMin = -1;
        const multiplier = this.getShiftMultiplierForYAxis();
        if (multiplier > 0) {
            this.xAxisOffset = this.xAxisOffset - multiplier * (this.isPrinting ? XAXIS_OFFSET_PRINT_SHIFT : XAXIS_OFFSET_NORMAL_SHIFT);
            this.xAxislabelOffset = this.xAxislabelOffset  + multiplier * XAXIS_LABEL_OFFSET_NORMAL_SHIFT;
            this.chartHeight = this.chartHeight + multiplier * CHART_HEIGHT_NORMAL_SHIFT;
            this.softMin = -1 - multiplier;
        }
    }

    private getShiftMultiplierForYAxis(): number {
        let c = 0;
        if (this.isCrewDrivingToday()) {
            c = c + 1;
        }
        if (this.groupedInfrigments) {
            c = c + this.groupedInfrigments.length;
        }
        return c;
    }

    private createChart() {
        if (!this.firstRendered && this.viewInitiated && this.dataInitiated) {
            this.firstRendered = true;
            this.drawHighcharts();
            for (const ds of this.dailyChartInput.dataset) {
                this.addDataToSeries(ds);
            }
            this.addDataToSeries(this.createShiftDataSeries(this.dailyChartInput.workingTimeRows,
                this.dailyChartInput.dateOfChart));

            this.createInfrigmentsDataSeries(this.dailyChartInput.offences,
                this.dailyChartInput.dateOfChart / 1000, this.groupedInfrigments)
                .forEach(ds => {
                    this.addDataToSeries(ds);
            })
        }
    }

    ngAfterViewInit() {
        this.viewInitiated = true;
        if (!!this.dailyChartInput) { this.setOffsetForBorderCrossingItems(this.dailyChartInput.borderCrossingItems); }
        this.createChart();
    }

    drawHighcharts() {
        this.ngZone.runOutsideAngular(() => {
            Highcharts.setOptions({
                time: {
                    getTimezoneOffset: (timestamp) =>  {
                        return -moment.tz(timestamp, this.timezone).utcOffset();
                    }
                }
            });
            this.highChartObject = Highcharts.chart({
                title: {
                    text: ''
                },
                plotOptions: {
                    area: {
                        fillOpacity: 1
                    },
                    series: {
                        animation: false,
                        turboThreshold: 10
                    }
                },
                chart: {
                    renderTo: this.elementId,
                    panning: {
                        enabled: true,
                        type: 'y'
                    },
                    marginTop: 0,
                    marginBottom: 20
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%k}',
                        y: this.xAxislabelOffset,
                        style: {
                            color: COLOR_CHART_GREY
                        }
                    },
                    tickInterval: 3600 * 1000,
                    tickColor: COLOR_CHART_GREY,

                    gridLineColor: '#000000',
                    gridLineWidth: 0,
                    gridLineDashStyle: 'Solid',
                    minorGridLineWidth: 0,
                    minorGridLineColor: '#000000',
                    offset: this.xAxisOffset,
                    max: (this.startDayInMoment.valueOf() + (1000 * 60 * 60 * this.dailyChartService.getNumberOfHoursInDay(this.startDayInMoment))),
                    min: this.startDayInMoment.valueOf(),
                    lineWidth: 2,
                    lineColor: '#000000'
                },
                yAxis: {
                    title: {
                        text: undefined
                    },
                    labels: {
                        enabled: false
                    },
                    softMin: this.softMin,
                    softMax: 4,
                    minorTickLength: 0,
                    minorTickInterval: 1,
                    minorGridLineDashStyle: 'Solid',
                    minorGridLineWidth: 1,
                    minorGridLineColor: '#DDDDDD',
                    lineWidth: 2,
                    lineColor: '#000000',
                },
                credits: {
                    enabled: false
                },
                boost: {
                    enabled: true,
                    useGPUTranslations: true,
                },
                exporting: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                    // below lines are copied from drivers-table.components.ts. We can not use here, because mydata is not available.
                    // Mydata field is custom data added to HihgChart series. We can not add custom data because we use chart.addSeries method. (method parameter type check)
                    // The logic how to add data to HighChart should be refactored to add custom data in this HighChart object (where this.highChartObject is created).

                    // formatter: function () {
                //         const visibileData = JSON.parse(this.point.mydata);
                //         let tooltipText: string;
                //         // if (this.series.index === 0) {
                //         //     tooltipText = this.series.name +
                //         //         '<br>' +
                //         //         '<b>' + 'VIN: ' + '</b>' + visibileData.vehicleIdentificationNumber + '<br>' +
                //         //         '<b>' + that._translate.instant('Vehicle registration number')  + ': </b>' + visibileData.vehicleRegistrationNumber + '<br>' +
                //         //         '<b>' + that._translate.instant('Odometer start') + ': </b>' + visibileData.odoStart + '<br>' +
                //         //         '<b>' + that._translate.instant('Odometer end') + ': </b>' + visibileData.odoEnd + '<br>';
                //         // } else {
                //             tooltipText = this.series.name +
                //                 '<br>' + that.translate.instant('Duration') + ': ' +
                //                 ((Math.floor(visibileData.duration / 60) < 10) ?
                //                     '0' + Math.floor(visibileData.duration / 60) :
                //                     Math.floor(visibileData.duration / 60)) + ':' +
                //                 ((visibileData.duration % 60 < 10) ?
                //                     '0' + Math.floor(visibileData.duration % 60) :
                //                     Math.floor(visibileData.duration % 60)) +
                //                 '<br>' + that.translate.instant('Start date') + ' :' +
                //                 moment(
                //                     new Date((visibileData.day + visibileData.startTime * 60) * 1000)
                //                 ).format('HH:mm') +
                //                 '<br>' + that.translate.instant('End date') + ' :' +
                //                 moment(
                //                     new Date((visibileData.day + visibileData.endTime * 60) * 1000)
                //                 ).format('HH:mm');
                //             ;
                //         // }
                //         return tooltipText;
                //     },
                    // headerFormat: '<span style="font-size: 10px">{point.key: ' + this.dateFormat + ' }</span><br/>',
                    // pointFormat: '',
                    // footerFormat: ''
                },
            }, () =>  {
                // console.log(moment(new Date()).format('YYYY.MM.DD HH:mm:ss.SSS') + this.chartLoaded + ' ' + this.indexOfChart + ' ' + this.responseIndex);
                if (this.isPrinting && !this.dailyChartService.printingInProgress) { return; }
                const event = this.isPrinting ?
                    this.dailyChartService.getNextEvent(this.indexOfChart, this.responseIndex, this.typeOfChart) :
                    { index: this.indexOfChart, responseIndex: this.responseIndex };
                setTimeout(() => {
                    if (!!event) { this.chartLoaded.emit(event); }
                }, 100);
                this.highChartObjectCreated.emit(this.highChartObject);
            });
        });
    }

    generateUniqueId(): string {
        return uuidv4();
    }

    calculateInfolinePositions() {
        if (this.dailyChartInput) {
            if (this.dailyChartInput.borderCrossingItems?.length > 0) {
                this.calculatePositionForInfolineItem(this.dailyChartInput.borderCrossingItems);
            }
            if (this.dailyChartInput.ferryItems?.length > 0) {
                this.calculatePositionForInfolineItem(this.dailyChartInput.ferryItems);
            }
            if (this.dailyChartInput.vehicleItems?.length > 0) {
                this.calculatePositionForVehicleInfolineItems(this.dailyChartInput.vehicleItems);
            }
            if (this.dailyChartInput.driversOnDay?.length > 0) {
                this.calculatePositionForVehicleInfolineItems(this.dailyChartInput.driversOnDay);
            }
            if (this.dailyChartInput.noCardInsertedOnDay?.length > 0) {
                this.calculatePositionForVehicleInfolineItems(this.dailyChartInput.noCardInsertedOnDay);
            }
            if (this.dailyChartInput.lastDownloadedTime && this.dailyChartInput.lastDownloadedTime > 0) {
                this.calculatePositionForLstDownloadWarning(this.dailyChartInput.lastDownloadedTime);
            }
        }
    }

    calculatePositionForInfolineItem(infolineItems: InfoLineitemDescription[]) {
        this.sortInfolineItems(infolineItems);
        let lastProcessedMinute = 0;
        let sumPercent = 0;
        for (const item of infolineItems) {
            item.position = ((item.crossingMinute - lastProcessedMinute) / (this.dailyChartService.getNumberOfHoursInDay(moment(this.dailyChartInput.dateOfChart)) * 60)) * (100);
            sumPercent += item.position;
            lastProcessedMinute = item.crossingMinute;
        }
    }

    calculatePositionForVehicleInfolineItems(infolineItems: InfoLineitemDescription[]) {
        this.sortInfolineItems(infolineItems);
        for (const item of infolineItems) {
            item.position = ((item.crossingMinute) / (1440)) * (100);
        }
    }

    sortInfolineItems(infolineItems: InfoLineitemDescription[]) {
        infolineItems.sort((a, b) => {
            if (a.crossingMinute < b.crossingMinute) {
                return -1;
            }
            if (a.crossingMinute > b.crossingMinute) {
                return 1;
            }
            return 0;
        })
    }

    calculatePositionForLstDownloadWarning(lastDownloadTime: number) {
        this.lastDownloadInfolineDescription = new InfoLineitemDescription();
        this.lastDownloadInfolineDescription.lastDownloadMoment = moment(lastDownloadTime).tz(this.timezone);
        this.lastDownloadInfolineDescription.crossingMinute = (lastDownloadTime - this.dailyChartInput.dateOfChart) / 1000 / 60;
        this.lastDownloadInfolineDescription.position = ((this.lastDownloadInfolineDescription.crossingMinute) / (1440)) * (100);
    }

    setOffsetForBorderCrossingItems(borderCrossingItems: InfoLineitemDescription[]) {
        let offset = 0;
        const halfIconWidth = this.isPrinting ? 8 : 12;
        borderCrossingItems.forEach((item, index) => {
            item.offset = offset;
            if (item.position / 100 * (this.borderCrossingContainer.nativeElement.offsetWidth - halfIconWidth) - (!!index ? 2 * halfIconWidth : halfIconWidth) - offset < 0) {
                offset += !!index ? 2 * halfIconWidth : halfIconWidth;
            } else {
                offset = 0;
            }
        });
    }

    addDataToSeries(dataSet: DataSet) {
        switch (dataSet.type) {
            case DataTypes.BREAK_REST:
                this.addRestSeriesToChart(this.highChartObject, dataSet.data);
                break;
            case DataTypes.DRIVING:
                this.addDriveSeriesToChart(this.highChartObject, dataSet.data);
                break;
            case DataTypes.AVAILABILITY:
                this.addAvailableSeriesToChart(this.highChartObject, dataSet.data);
                break;
            case DataTypes.WORK:
                this.addWorkSeriesToChart(this.highChartObject, dataSet.data);
                break;
            case DataTypes.WORKING_TIME:
                if (this.typeOfChart === TabOption.DRIVER) {
                    this.addWorkingTimeSeriesToChart(this.highChartObject, dataSet.data);
                }
                break;
            case DataTypes.CREW_DRIVING:
                this.addCrewDrivingSeriesToChart(this.highChartObject, dataSet.data);
                break;
            case DataTypes.INFRINGMENT:
                if (this.typeOfChart === TabOption.DRIVER) {
                    this.addInfringmentSeriesToChart(this.highChartObject, dataSet.data);
                }
                break;
        }
    }

    addDriveSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaSeriesToChart(chart, COLOR_DRIVE, this.translate.instant('Report.DailyChart.series3Name'), data);
    }

    addRestSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaSeriesToChart(chart, COLOR_REST, this.translate.instant('Report.DailyChart.series1Name'), data);
    }

    addAvailableSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaSeriesToChart(chart, COLOR_AVAILABLE, this.translate.instant('Report.DailyChart.series2Name'), data);
    }

    addWorkSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaSeriesToChart(chart, COLOR_WORK, this.translate.instant('Report.DailyChart.series4Name'), data);
    }

    addWorkingTimeSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaRangeSeriesToChart(chart, COLOR_WORKING_TIME, this.translate.instant('Report.DailyChart.series5Name'), data);
    }

    addCrewDrivingSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaRangeSeriesToChart(chart, COLOR_CREW_DRIVING, this.translate.instant('Report.DailyChart.series6Name'), data);
    }

    addInfringmentSeriesToChart(chart: Chart, data: any[]) {
        this.addAreaRangeSeriesToChart(chart, COLOR_INFRINGMENT, this.translate.instant('Report.DailyChart.series7Name'), data);
    }


    /**
     * Series object option can be set here in this method
     * @param chart
     * @param color
     * @param name
     * @param data
     */
    addAreaSeriesToChart(chart: Chart, color: any, name: string, data: any) {
        chart.addSeries({
            showInLegend: false,
            name: name,
            data: data,
            type: SERIES_TYPE_AREA,
            lineWidth: 0,
            color: color,
            clip: false,
            boostThreshold: 1,
            enableMouseTracking: false
        })
    }

    addAreaRangeSeriesToChart(chart: Chart, color: any, name: string, data: any) {
        chart.addSeries({
            showInLegend: false,
            name: name,
            data: data,
            type: SERIES_TYPE_AREARANGE,
            lineWidth: 0,
            color: color,
            clip: false,
            boostThreshold: 1,
            enableMouseTracking: false
        })
    }

    // Patterns not working in angular-highcharts
    // addAreaRangeSeriesToChartWithPattern(chart: Chart, color: any, name: string, data: any) {
    //     chart.addSeries({
    //         showInLegend: false,
    //         name: name,
    //         data: data,
    //         type: SERIES_TYPE_AREARANGE,
    //         lineWidth: 0,
    //         color: {
    //             patternIndex: 1
    //         } as any,
    //         clip: false,
    //         boostThreshold: 1
    //     })
    // }

    setDayOfWeekOfChart() {
        this.startDayInMoment.locale(this.currentLang);
        this.nameOfDayInWeek = this.startDayInMoment.format('dddd');
    }

    isVisibleDataOnChart(): boolean {
        if (this.dailyChartInput.dataset.length === 0) {
            return false;
        }
        for (const ds of this.dailyChartInput.dataset) {
            if (ds.data.length > 0) {
                return true;
            }
        }
        return false;
    }

    createShiftDataSeries(workingtimeRows: OneWorkingTimeRow[], recordDate: number): DataSet {
        const dataSet = new DataSet(DataTypes.WORKING_TIME);
        for (const oneWorkingtimeRow of workingtimeRows) {
            // we have to set empty records between valid data to have correct chart diagram
            for (let j = dataSet.data.length; j < oneWorkingtimeRow.startTime; j++) {
                dataSet.data[j] = [recordDate + (1000 * 60 * j), null];
            }
            for (let i = oneWorkingtimeRow.startTime;
                 i <= (((oneWorkingtimeRow.endTime > oneWorkingtimeRow.startTime) && (oneWorkingtimeRow.endTime < 1440))
                     ? oneWorkingtimeRow.endTime : 1440)  ; i++) {
                dataSet.data[i] = [recordDate + (1000 * 60 * i), this.workingTimeDataValueStart, this.workingTimeDataValueStart - 1];
            }
        }
        return dataSet;
    }

    createInfrigmentsDataSeries(infrigments: Offence[], recordDateInSec: number, groupedInfringments: Offence[][]): DataSet[] {
        const dataSetList = [];
        for (let i = 0; i < groupedInfringments.length; i++) {
            const infrigmentArray = groupedInfringments[i];
            const dataSet = new DataSet(DataTypes.INFRINGMENT);
            infrigmentArray.forEach(item => {
                for (let j = dataSet.data.length; j < (item.startTime - recordDateInSec) / 60; j++) {
                    dataSet.data[j] = [recordDateInSec / 60 + (1000 * 60 * j), null];
                }
                for (let k = (item.startTime - recordDateInSec) / 60;
                     k <= (((item.endTime - recordDateInSec >= (item.startTime - recordDateInSec)) && ((item.endTime - recordDateInSec) / 60 < 1440))
                         ? (item.endTime - recordDateInSec) / 60 : 1440)  ; k++) {
                    dataSet.data[k] = [recordDateInSec * 1000 + (1000 * 60 * k), this.workingTimeDataValueStart - (i + 1), this.workingTimeDataValueStart - (i + 1) - 1];
                }
            })
            dataSetList.push(dataSet);
        }
        return dataSetList;
    }

    createGroupedInfringments(infrigments: Offence[]): Offence[][] {
        const groupedInfringments = [];
        if (this.isInfringementToday() && this.typeOfChart === TabOption.DRIVER) {
            infrigments.forEach(infrigment => {
                let itemAdded = false;
                for (let i = 0; i < groupedInfringments.length; i++) {
                    let item = groupedInfringments[i];
                    if (!Array.isArray(item)) {
                        item = [];
                    }
                    if (!this.isConflictInInfringments(item, infrigment)) {
                        item.push(infrigment);
                        itemAdded = true;
                        break;
                    }
                }
                if (!itemAdded) {
                    const item = [];
                    item.push(infrigment);
                    groupedInfringments[groupedInfringments.length] = item;
                }
            })
        }
        return groupedInfringments;
    }

    isConflictInInfringments(infringments: Offence[], infringment: Offence): boolean {
        for (let i = 0; i < infringments.length; i++) {
            const item = infringments[i];
            if (this.isConflictBetweenInfringments(item, infringment)) {
                return true;
            }
        }
        return false;
    }

    isConflictBetweenInfringments(infringment1: Offence, infringment2: Offence): boolean {
        return infringment2.offenceStartMoment.isBetween(infringment1.offenceStartMoment, infringment1.offenceEndMoment) ||
            infringment2.offenceEndMoment.isBetween(infringment1.offenceStartMoment, infringment1.offenceEndMoment);
    }

    /**
     *
     * Remove this if data is integrated with BE
     */
    createMockCrewDrivingData(recordDate: number): DataSet {
        const dataSet = new DataSet(DataTypes.CREW_DRIVING);
        for (let i = 0; i <= 60; i++) {
            dataSet.data[i] = [recordDate + (1000 * 60 * i), -1, -2];
        }
        return dataSet;
    }

    isNameOfDayIsToday(dayString: string): boolean {
        return dayString === this.nameOfDayInWeek;
    }

    checkForTimeOffsetChange() {
        const zone = this.timezone;
        const m = moment.tz(this.dailyChartInput.dateOfChart, zone);
        const a = moment(m).startOf('day');
        const b = moment(m).add(1, 'day').startOf('day');

        const h = b.diff(a, 'hours'); // 23, 24, 25, etc.
        if (h === 23) {
            this.timeOffsetChangingMinus = true;
        } else if (h === 25) {
            this.timeOffsetChangingPlus = true;
        }
    }

    isCrewDrivingToday(): boolean {
        return this.dailyChartInput.dataset.filter(value =>
            value.type === DataTypes.CREW_DRIVING && value.data.length > 0
        ).length > 0;
    }

    isInfringementToday(): boolean {
        if (this.typeOfChart === TabOption.DRIVER) {
            return this.dailyChartInput.offences?.length > 0;
        } else {
            return this.dailyChartInput.faults?.length > 0 || this.dailyChartInput.drivingsWithoutCard?.length > 0;
        }
    }

    isSameDay(startDate: number, endDate: number) {
        return this.dailyChartInput.dateOfChart <= startDate * 1000 && this.dailyChartInput.dateOfChart + 86400 * 1000 >= endDate * 1000;
    }

    get faultsAndDrivingsWithoutCard() {
        return [...this.dailyChartInput.drivingsWithoutCard, ...this.dailyChartInput.faults];
    }

    isShiftIsAvailableInChart(): boolean {
        return this.typeOfChart === this.tabOptions.DRIVER;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.highChartObject.reflow();
    }

    ngOnDestroy() {
        if (this.highChartObject) {
            this.highChartObject.destroy();
        }
        if (!!this.chartDataChangedSubscription) {
            this.chartDataChangedSubscription.unsubscribe();
            this.chartDataChangedSubscription = null;
        }
    }

}
