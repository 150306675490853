import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'app/helpers/globals';
import { map, share } from 'rxjs/operators';

export type GenerateInfringementsForCompanyAndAllSubCompaniesStatResponse = {
  queueSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class AdminPanelService {

  constructor(
    private readonly http: HttpClient
  ) { }


  generateInfringementsForCompanyAndAllSubCompaniesStat(){
    return this.http.get<GenerateInfringementsForCompanyAndAllSubCompaniesStatResponse>(`${API_URL}generateInfringementsForCompanyAndAllSubCompaniesStat`, 
      { withCredentials: true })
    .pipe(share()).pipe(map((response) => response));
  }

  generateInfringementsForCompanyAndAllSubCompaniesCancel(){
    return this.http.post(`${API_URL}generateInfringementsForCompanyAndAllSubCompaniesCancel`,{}, 
      { withCredentials: true })
    .pipe(share()).pipe(map((response) => response));
  }

  generateInfringementsForCompanyAndAllSubCompanies(clientId: number){
    return this.http.post(`${API_URL}generateInfringementsForCompanyAndAllSubCompanies`, {clientId: clientId}, 
      { withCredentials: true })
    .pipe(share()).pipe(map((response) => response));
  }

  calculateBills(year:number, quarter:number){
    return this.http.get(`${API_URL}calculateBills2023Q1?year=${year}&q=${quarter}`, 
      { withCredentials: true })
    .pipe(share()).pipe(map((response) => response));
  }

  reloadDataForFile(hash:string){
    return this.http.get(`${API_URL}reloadDataForFileWith?hash=${hash}`, 
      { withCredentials: true })
    .pipe(share()).pipe(map((response) => response));
  }

}
